



































































import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, PackageService } from "src/services";
import { Toast } from "vant";
import { UserService } from "src/services";
import EnumUtils from "src/utils/enum-utils";
import { ServiceType } from "src/enums";

@Component
export default class Service extends Vue
{
    
    /**
     * 使用记录
     * @private
     * @returns void
     */
    private rightsRecords: Array<any> = [];

    /**
     * 失效
     * @private
     * @returns void
     */
    private invalidData: Array<any> = [];

    /**
     * 生效中数据
     * @private
     * @returns void
     */
    private dataList: Array<any> = [];

    /**
     * 跳转权益
     * @private
     * @returns void
     */
    private onPower(data: any): any
    {
        if (data.serviceType == 11 || data.serviceType == 10)
        {
            this.$router.push({name: "powerReportIntroduce", query:{memberServiceItemId : data.memberServiceItemId }});
        }
        else
        {
            this.$router.push({name: "power", query:{memberServiceItemId : data.memberServiceItemId}});
        }
    }

    /**
     * 获取服务类型
     * @private
     * @returns string
     */
    protected getServiceType(value: number): string
    {
        return (EnumUtils.getEntry(value, ServiceType) && EnumUtils.getEntry(value, ServiceType).description) || "";
    }
    
    /**
     * 获取权益数量
     * @private
     * @returns void
     */
    private async getMyRightsRecords(): Promise<void>
    {
        try
        {
            let {content: result} = await UserService.instance.getMyRightsRecords();
            if(result.data)
            {
                this.rightsRecords = result.data;
            }
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 获取我使用中的权益 0: 生效中； 1：已失效
     * @private
     * @returns Promise<void>
     */
    private async getMyRights(status: number): Promise<void>
    {
        try
        {
            let {content: result} = await UserService.instance.getMyRights(status);
            if(status == 0)
            {
                this.dataList = result.data;
            }
            else
            {
                this.invalidData = result.data;
            }
           
        }
        catch(err)
        {
            Toast(JSON.stringify(err));
        }
    }

    /**
     * 组件创建构造
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getMyRightsRecords();
        this.getMyRights(0);
        this.getMyRights(1);
    }
}
